<template>
  <div class="v-service work">
    <div class="v-service-block area center">
        <div class="wrapper-notifications default">
            <p>Сейчас мы проводим очень важные работы, просим прощения за доставленные неудобства</p>
        </div>
        <div class="v-service-img">
            <img src="@/assets/img/svg/service-work.svg" alt="">
        </div>
        <div class="v-service-title">
            <p>Ориентировачное время возобновления работы сервиса - {{ resumingDate }}</p>
        </div>
        <div class="v-service-icon">
             <img src="@/assets/img/svg/service-icon.svg" alt="">
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-service-work',
  data() {
      return {
          resumingDate: '05.07.2021, 14:00'
      }
  }
}
</script>

<style>

</style>